/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import firebase from 'firebase/app'
import 'firebase/auth'
import router from '@/router'
import auth from '@/auth/authService'
import store from '@/store/store'
import LoginService from '@/services/api/LoginService'
import UserService from '@/services/api/UserService'
import NotificationService from '@/services/api/NotificationService'
import Vue from 'vue'
import { setActiveInstitution } from '@/util/Institution'
import { isAdmin } from '@/util/Util'
// @ts-ignore
import get from 'lodash/get';
import i18n from '@/i18n/i18n'

export default {
  loginAttempt({ dispatch }: any, payload: any) {
    // New payload for login action
    const newPayload = {
      updateUsername: payload.updateUsername,
      userDetails: payload.userDetails,
      notify: payload.vs.notify,
      closeAnimation: payload.closeAnimation,
      acl: payload.acl
    }

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {
      // TODO Implement remember me
      dispatch('login', newPayload)
    } else {
      // Try to login
      dispatch('login', newPayload)
    }
  },
  login({ commit, state, dispatch }: any, payload: any) {
    const service: LoginService = LoginService.build(payload.notify)

    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      payload.notify({
        title: i18n.t('login'),
        text: i18n.t('voce-ja-esta-autenticado'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })

      return false
    }

    //console.log('payload', payload)


    const paramsLogin = {
      email: payload.userDetails.email,
      password: payload.userDetails.password
    }

    if ('s_email' in payload.userDetails) {
      paramsLogin['s_email'] = payload.userDetails.s_email
    }

    service
      .post('v1/login', paramsLogin)
      .then(
        user => {
          payload.user = user
          payload.redirect = true
          dispatch('loginSuccess', payload)
        },
        (error: any) => {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()
          if (error.status === 401 && error.data === 'unverified_account') {
            payload.notify({
              time: 20000,
              title: i18n.t('atencao-0'),
              text:
                i18n.t('esta-conta-ainda-nao-foi-confirmada-por-'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            payload.notify({
              time: 5000,
              title: i18n.t('erro'),
              text:
                i18n.t('nao-foi-possivel-realizar-o-login-verifi'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        }
      )
  },
  loginSuccess({dispatch}, payload) {
    dispatch('updateUsername', {
      user: payload.user,
      notify: payload.notify,
      isReloadRequired: true
    })

    // Close animation if passed as payload
    if (payload.closeAnimation) payload.closeAnimation()

    const permissions: any = []
    if (payload.user.permissions) {
      payload.user.permissions.forEach(element => {
        permissions.push(element.name)
      })
    }

    store.commit('UPDATE_USER_INFO', payload.user)

    if (payload.user.people && payload.user.people.length > 0) {
      store.commit('UPDATE_ACTIVE_PERSON', payload.user.people[0])
    }

    if (payload.user.roles && payload.user.roles.length > 0) {
      store.commit('UPDATE_DEFAULT_ACTIVE_ROLE', payload.user.roles)
    }

    if (payload.user.pingInterval) {
      Vue.prototype.devLog('loginsuccess', i18n.t('got-ping-interval-from-user-login-settin'), payload.user.pingInterval)
      store.commit('SET_PING_INTERVAL', payload.user.pingInterval)
    }

    const slowKbpsRate = (get(payload, 'user.slow_kbps_rate', null) || get(payload, 'slow_kbps_rate', null))
    if (slowKbpsRate !== null) store.dispatch('setSlowKbpsRate', slowKbpsRate)

    // Update available institutions for user.
    if (payload.user.institutions) {
      Vue.prototype.devLog('loginsuccess', i18n.t('got-institution-list-from-user-login-set'), payload.user.institutions)
      store.dispatch('institution/updateAvailableInstitutions', payload.user.institutions)
    }

    if (payload.acl) {
      const userInfoStr = localStorage.getItem('userInfo')
      const userInfo = userInfoStr ? JSON.parse(userInfoStr) : []
      const activeInstitution = store.getters['institution/active']

      // Gets how many institutions the user has access to.
      const institutionCount = (
        'user' in payload &&
        'institutions' in payload.user &&
        'length' in payload.user.institutions &&
        payload.user.institutions.length
      ) || 0

      // If the user has more than one institution and there are none currently active,
      // set the first one to be the active one.
      // If the user is an admin do not automatically set the active institution.
      if (institutionCount > 1 && ((activeInstitution && !activeInstitution.id) || false) && !isAdmin()) {
        setActiveInstitution(payload.user.institutions[0], payload.acl).then((success) => {
          store.dispatch('institution/updateModalVisibility', true)
        })
      // If he only has one institution, set that by the default the active one.
      } else if (institutionCount === 1 && payload.acl && !isAdmin()) {
        setActiveInstitution(payload.user.institutions[0], payload.acl)
      }

      // If there is any active institution, use its permissions,
      // otherwise fallback to the user's default permissions.
      if (activeInstitution && activeInstitution.id && payload.user.institutions) {
        const institution = payload.user.institutions.find(institution => {
          return institution.id === activeInstitution.id
        })
        if (institution) {
          payload.acl.setPermissions(institution.permissions)
        }
      } else {
        payload.acl.setPermissions(userInfo.permissions)
      }
    }

    if (payload.restrictUserLogged) {
      payload.restrictUserLogged(payload.user)
    }

    // Redirect
    let redirectRoute = '/'
    if ('redirect' in payload && payload.redirect ) {
      const appInfoStr: any = localStorage.getItem('appInfo')
      let appInfo: any = appInfoStr ? JSON.parse(appInfoStr) : {}
      if (
        appInfo &&
        appInfo.lastUrlPath &&
        appInfo.lastUrlPath !== '/register'
      ) {
        const lastUrlRoute = router.resolve(appInfo.lastUrlPath)
        if (payload.acl && lastUrlRoute && lastUrlRoute.route.meta && payload.acl.hasAnyPermission(lastUrlRoute.route.meta.rule)) {
          redirectRoute = appInfo.lastUrlPath
        }
      }

      if (payload.user && 'error' in payload.user && payload.user.error === 'first_access') {
        redirectRoute = '/first-access'
      }

      // Reminder
      // Our vuex store must be refactored at some point in the future, the need
      // to add the following block of code in *this place* is the main sign of it.
      // Not only that, but there are mutations in the main store when they should
      // have been in auth's mutation, and the stages of the login process are kinda
      // scattered over the actions in this file.
      const notification = NotificationService.build(Vue.prototype)
      if (payload.user.unreadNotifications) {
        store.dispatch('resetUnreadNotifications', notification.constructManyNotifications(payload.user.unreadNotifications))
      }
    }
    // ---------------------------------------------------------------------------

    if (payload.callbackSuccess) {
      payload.callbackSuccess()
    } else {
      router.push(redirectRoute).catch(()=>{})
    }
  },
  socialLogin ({dispatch, commit, state}, payload) {
    const user = payload.user
    if (user && !('authenticated' in user)) {
      const newPayload = {
        userDetails: {
          name: payload.user.name,
          email: payload.user.email,
          person_type: 'student'
        },
        vs: payload.vs,
        acl: payload.acl,
        closeAnimation: () => {
          payload.vs.loading.close()
        },
        restrictUserLogged: payload.restrictUserLogged
      }
      store.dispatch('auth/registerRestrictUser', newPayload)

    } else if ('token' in user) {
      const newPayload = {
        user: user,
        updateUsername: true,
        userDetails: {
          email: user.email,
        },
        notify: payload.vs.notify,
        closeAnimation: payload.closeAnimation,
        acl: payload.acl,
        restrictUserLogged: payload.restrictUserLogged,
        redirect: true
      }
      dispatch('loginSuccess', newPayload)
    }
  },

  logout({ commit, state, dispatch }: any, payload: any) {
    const service = LoginService.build()
    'vs' in payload && payload.vs.loading()
     const domainConfig = store.getters['common/domainConfig']
     let redirectUrl =  get(domainConfig, 'redirectFromLogoutUrl', '/login')

    // Regardless if the logout route was successfull, clear localstore and loading animation.
    const finalize = () => {
      store.dispatch('resetUserInfo').then((finished) => {
        store.dispatch('institution/selectActiveInstitution', null)
        store.dispatch('institution/resetStore')
        auth.logOut()
        'vs' in payload && payload.vs.loading.close()
        if(redirectUrl === '/login'){
          router.push(redirectUrl)
        }else{
          window.location.replace(redirectUrl)
        }

      }, (error) => {
        if ('vs' in payload) {
          payload.vs.loading.close()
          Vue.prototype.notifyError(payload.vs, i18n.t('nao-foi-possivel-limpar-o-estado-da-apli'))
        }
      })
    }

    service.post('v1/logout', {}).then(
      data => {

        // Delete local storage lastUrlPath
        let appInfoStr: any = localStorage.getItem('appInfo')
        let appInfo: any = appInfoStr ? JSON.parse(appInfoStr) : {}
        delete appInfo.lastUrlPath
        localStorage.setItem('appInfo', JSON.stringify(appInfo))

        finalize()
      },
      (error: any) => {
        finalize()
      }
    )
  },
  changePasswordAttempt({ dispatch }: any, payload: any) {
    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation,
      acl: payload.acl,
      flags: get(payload, 'flags', {})
    }

    dispatch('changePassword', newPayload)
  },
  changePassword({ commit, state, dispatch }: any, payload: any) {
    const service = LoginService.build(payload.notify)

    const noHome = !!get(payload, 'flags.nohome', false)

    service
      .post(`v1/password/change`, {
        password: payload.userDetails.password,
        password_confirmation: payload.userDetails.passwordConfirm
      })
      .then(
        data => {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()
          if (payload.notify) {
            payload.notify({
              time: 5000,
              title: i18n.t('sucesso'),
              text: i18n.t('senha-alterada-com-sucesso'),
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success'
            })
          }
          const permissions: any = []
          if (data.permissions) {
            data.permissions.forEach(element => {
              permissions.push(element.name)
            })
          }
          if (data.people && data.people.length > 0) {
            store.commit('UPDATE_ACTIVE_PERSON', data.people[0])
          }

          store.dispatch('updateUserInfo', {permissions: data.permissions, roles: data.roles})

          payload.acl.setPermissions(data.permissions)

          if (noHome) return

          router.push('/').then(() => {
            if (data.roles && data.roles.length > 0) {
              store.commit('UPDATE_DEFAULT_ACTIVE_ROLE', data.roles)
            }
          })
        },
        (error: any) => {
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 5000,
            title: i18n.t('erro-0'),
            text: i18n.t('nao-foi-possivel-alterar-a-senha'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      )
  },
  resetPassword({ commit, state, dispatch }: any, payload: any) {
    const service = LoginService.build(payload.vs)

    service
      .post('v1/password/reset', {
        token: payload.userDetails.token,
        email: payload.userDetails.email,
        password: payload.userDetails.password,
        password_confirmation: payload.userDetails.passwordConfirm
      })
      .then(
        data => {
          if (payload.closeAnimation) payload.closeAnimation()
          router.push('/login')

          payload.vs.notify({
            time: 5000,
            title: i18n.t('sucesso'),
            text: i18n.t('senha-alterada-com-sucesso-0'),
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success'
          })
        },
        (error: any) => {
          if (payload.closeAnimation) payload.closeAnimation()
        }
      )
  },
  forgotPassword({ commit, state, dispatch }: any, payload: any) {
    const service = LoginService.build(payload.notify)

    service.post('v1/password/email', {
        email: payload.email
      })
      .then(
        data => {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 20000,
            title: `${i18n.t('sucesso')}!`,
            text: data.success,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success'
          })
        },
        (error: any) => {
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 5000,
            title: i18n.t('erro-1'),
            text:
              i18n.t('houve-um-problema-ao-realizar-sua-operacao'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      )
  },

  registerUser({ dispatch }: any, payload: any) {
    const service = UserService.build(payload.vs)

    service.post(payload.userDetails.social ? `store_social` : 'store', payload.userDetails).then(
      user => {
        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        let message = i18n.t('usuario-criado-com-sucesso')
        if (!payload.userDetails.social) {
          message = i18n.t('message-um-email-foi-enviado-para-ativar', [message])
        }

        payload.vs.notify({
          time: 10000,
          title: message,
          text: user.success,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })

        if (payload.userDetails.social) {
          const newPayload = {
            user: user,
            updateUsername: true,
            userDetails: {
              email: user.email,
              password: payload.userDetails.password
            },
            notify: payload.notify,
            closeAnimation: payload.closeAnimation
          }
          dispatch('login', newPayload)
        } else {
          router.push('login')
        }

      },
      (error: any) => {
        if (payload.closeAnimation) payload.closeAnimation()
        if (payload.showErrors) payload.showErrors(error)

      }
    )
  },

  updateUsername({ commit }: any, payload: any) {
    const newUserData = Object.assign({}, payload.user)
    //commit('UPDATE_AUTHENTICATED_USER', newUserData)

    store.commit('UPDATE_USER_INFO', {
      displayName: newUserData.name
    })

    // If reload is required to get fresh data after update
    // Reload current page
    // if (payload.isReloadRequired) {
    //   router.push(router.currentRoute.query.to.toString() || '/')
    // }
  },
  updateAuthenticatedUser({ commit }: any, payload: any) {
    //commit('UPDATE_AUTHENTICATED_USER', payload)
    store.commit('UPDATE_USER_INFO', payload.user)
  }
}
