var render, staticRenderFns
import script from "./FeatherIcon.vue?vue&type=script&lang=js"
export * from "./FeatherIcon.vue?vue&type=script&lang=js"
import style0 from "./FeatherIcon.vue?vue&type=style&index=0&id=748aefbc&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/user/workspace/educatena/educatena-app-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('748aefbc')) {
      api.createRecord('748aefbc', component.options)
    } else {
      api.reload('748aefbc', component.options)
    }
    
  }
}
component.options.__file = "src/components/FeatherIcon.vue"
export default component.exports