var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center justify-center" },
    [
      _c(
        "vs-button",
        {
          class: `alternates-button ${_vm.buttonClass}`,
          attrs: { icon: _vm.iconMain },
          on: {
            click: function ($event) {
              return _vm.$emit("click-main", $event)
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _vm.alternatives.length > 0
        ? _c(
            "vs-dropdown",
            [
              _c("vs-button", {
                class: `alternates-dropdown ${_vm.dropdownClass}`,
                attrs: { type: "filled", icon: "expand_more" },
              }),
              _c(
                "vs-dropdown-menu",
                _vm._l(_vm.alternatives, function (alternate) {
                  return _c("vs-dropdown-item", { key: alternate }, [
                    _c(
                      "div",
                      {
                        class: `flex items-center justify-start cursor-pointer ${
                          "color" in alternate ? `text-${alternate.color}` : ""
                        }`,
                        on: {
                          click: function ($event) {
                            return _vm.alternateClicked(alternate)
                          },
                        },
                      },
                      [
                        "icon" in alternate
                          ? _c("vs-icon", { attrs: { icon: alternate.icon } })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "vs-dropdown-item-link-text px-4" },
                          [_vm._v(_vm._s(alternate.label))]
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }