import get from 'lodash-es/get'
import Moment from 'moment'
import { SerializableEvent } from './SerializableEvent'
export class MediaEvent extends SerializableEvent {
  serialize() {
    return {
      data: { ...this.event},
      type: this.type,
    }
  }
}

const serializeShareEvents = (obj: MediaEvent) => {

  const tracks = (
    'getTracks' in obj.event &&
    obj.event.getTracks()
  ) || []

  let trackInfo = []
  if (tracks.length > 0) trackInfo = tracks.map((track: MediaStreamTrack) => {
    return {
      id: track.id,
      kind: track.kind,
      label: track.label,
      muted: track.muted,
      capabilities: 'getCapabilities' in track && track.getCapabilities(),
      settings: 'getSettings' in track && track.getSettings(),
      constraints: 'getConstraints' in track.getConstraints(),
    }
  })

  return {
    data: {
      id: get(obj.event, 'id', null),
      tracks: trackInfo,
      emitted_at: obj.emitted_at,
    },
    type: obj.type
  }
}


const serializeRejectedEvents = (obj: MediaEvent) => {
  return {
    data: {
      code: get(obj.event, 'code', null),
      columnNumber: get(obj.event, 'columnNumber', null),
      data: get(obj.event, 'data', null),
      filename: get(obj.event, 'filename', null),
      lineNumber: get(obj.event, 'lineNumber', null),
      message: get(obj.event, 'message', null),
      name: get(obj.event, 'name', null),
      result: get(obj.event, 'result', null),
      emitted_at: get(obj.emitted_at, 'emitted_at', null),
    },
    type: obj.type
  }
}

export class MediaScreenRejectEvent extends MediaEvent {
  type = 'rejected_screen'
  serialize() {
    return serializeRejectedEvents(this)
  }
}

export class MediaScreenShareEvent extends MediaEvent {
  type = 'shared_screen'
  serialize() {
    return serializeShareEvents(this)
  }
}

export class UserMediaRejectEvent extends MediaEvent {
  type = 'rejected_usermedia'
  serialize() {
    return serializeRejectedEvents(this)
  }
}

export class UserMediaShareEvent extends MediaEvent {
  type = 'shared_usermedia'
  serialize() {
    return serializeShareEvents(this)
  }
}
