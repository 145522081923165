import Rest from '../Rest'

export default class DomainConfigService extends Rest {

  /**
   * @type {String}
   */
  static resource = '/api/v1/domains_config'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }
}
