import { SerializableEvent } from '@/models/events/SerializableEvent'
import get from 'lodash-es/get'
import Rest from '../Rest'

export class ExceptionEvent extends SerializableEvent {
  serialize() {
    const event = this.event
    return {
      type: get(event, 'type', 'general'),
      data: {...event}
    }
  }
}

export default class UserClientExceptionService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/user_client_exceptions'
  queue: Array<SerializableEvent> = []
  queueInterval: any = null

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  bootstrapQueue() {
    this.queueInterval = setInterval(this.processQueue, 1000)
  }

  shutdownQueue() {
    clearInterval(this.queueInterval)
  }

  processQueue() {
    if (this.queue.length > 0) {
      const event = this.queue.splice(0, 1)[0]
      if (event && event instanceof SerializableEvent) {
        this.registerException(event).then(
          (success) => {

          },
          (error) => {
            console.error('exception requeued')
            this.queue.push(event)
          }
        )
      } else {
        // For some reason the spliced event is an invalid object.
        console.error('Invalid event on exception queue.')
      }
    }
  }

  enqueue(event: SerializableEvent) {
    this.queue.push(event)
  }

  getClientExceptions(user_id) {
    return this.get(`/${user_id}`)
  }

  registerException(event: SerializableEvent) {
    return this.post(`/`, event.serialize())
  }

  quickRegister(text='Texto de teste', type='test', extra={}) {
    try {
      const event = new ExceptionEvent({text: text, type: type, ...extra})
      this.registerException(event)
    } catch (error) {
      // Do nothing, prevent the error from making another error
    }
  }
}
