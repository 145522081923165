/* eslint-disable implicit-arrow-linebreak */
/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
// @ts-nocheck
import Vue from 'vue'
import Router from 'vue-router'
import LoginService from '@/services/api/LoginService'
import auth from '@/auth/authService'
import store from '@/store/store'
import { isUserLoggedIn } from '@/util/Util'
import { page, time } from 'vue-analytics'
import NotificationService from './services/api/NotificationService'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'Dashboard',
          component: () => import('./views/pages/Home.vue')
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('./views/pages/Profile.vue'),
          meta: {
            pageTitle: 'meu-perfil',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'perfil', active: true }
            ]
          }
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: () => import('./views/pages/Notifications.vue'),
          meta: {
            pageTitle: 'notificacoes',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'notificacoes', active: true }
            ]
          }
        },
        // User
        {
          path: '/users',
          name: 'users',
          component: () => import('./views/apps/users/Index.vue'),
          meta: {
            rule: 'users.menu',
            pageTitle: 'users'
          }
        },
        {
          path: '/users/create',
          name: 'users_form',
          component: () => import('./views/apps/users/Form.vue'),
          props: true,
          meta: {
            rule: 'users.create',
            pageTitle: 'Users',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'users', url: '/users' },
              { title: 'Form', active: true }
            ]
          }
        },
        {
          path: '/users/:id/edit',
          name: 'users_form_edit',
          component: () => import('./views/apps/users/Form.vue'),
          props: true,
          meta: {
            rule: 'users.edit',
            pageTitle: 'users',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'users', url: '/users' },
              { title: 'Form', active: true }
            ]
          }
        },
        // Content
        {
          path: '/contents',
          name: 'contents',
          component: () => import('./views/apps/contents/Index.vue'),
          meta: {
            rule: 'contents.menu',
            pageTitle: 'Conteúdos'
          }
        },
        {
          path: '/contents/create',
          name: 'contents_form',
          component: () => import('./views/apps/contents/create/Form.vue'),
          props: true,
          meta: {
            rule: 'contents.create',
            pageTitle: 'Criar Conteúdo',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Conteúdos', url: '/contents' },
              { title: 'Criar', active: true }
            ]
          }
        },
        {
          path: '/contents/:id/edit',
          name: 'contents_form_edit',
          component: () => import('./views/apps/contents/create/Form.vue'),
          props: true,
          meta: {
            rule: 'contents.edit',
            pageTitle: 'Editar Conteúdo',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Conteúdos', url: '/contents' },
              { title: 'Editar', active: true }
            ]
          }
        },
        {
          path: '/contents/:id',
          name: 'contents_view',
          component: () => import('./views/apps/contents/view/ContentView.vue'),
          props: true,
          meta: {
            rule: 'contents.show',
            pageTitle: 'Conteúdo',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Visualizar Conteúdo', active: true }
            ]
          }
        },
        // Physical Questionnaire
        {
          path: '/physical_questionnaires',
          name: 'physical_questionnaires',
          component: () => import('./views/apps/physical_questionnaires/Index.vue'),
          meta: {
            rule: 'physical_questionnaires.menu',
            pageTitle: 'Conteúdos'
          }
        },
        {
          path: '/physical_questionnaires/create',
          name: 'physical_questionnaires_form',
          component: () => import('./views/apps/physical_questionnaires/Form.vue'),
          props: true,
          meta: {
            rule: 'physical_questionnaires.create',
            pageTitle: 'Criar Prova Física',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Prova Física', url: '/physical_questionnaires' },
              { title: 'Criar', active: true }
            ]
          }
        },
        {
          path: '/physical_questionnaires/:id/edit',
          name: 'physical_questionnaires_form_edit',
          component: () => import('./views/apps/physical_questionnaires/Form.vue'),
          props: true,
          meta: {
            rule: 'physical_questionnaires.edit',
            pageTitle: 'Editar Prova Física',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Prova Física', url: '/physical_questionnaires' },
              { title: 'Editar', active: true }
            ]
          }
        },
        // Organization
        {
          path: '/organizations',
          name: 'organizations',
          component: () => import('./views/apps/organizations/Index.vue'),
          meta: {
            rule: 'organizations.grid',
            pageTitle: 'Organizações'
          }
        },
        {
          path: '/organizations/create',
          name: 'organizations_form',
          component: () => import('./views/apps/organizations/Form.vue'),
          props: true,
          meta: {
            rule: 'organizations.create',
            pageTitle: 'Organizações',
            breadcrumb: [
              { title: 'Educatena', url: '/' },
              { title: 'Organizações', url: '/organizations' },
              { title: 'Form', active: true }
            ]
          }
        },
        {
          path: '/organizations/:id/edit',
          name: 'organizations_form_edit',
          component: () => import('./views/apps/organizations/Form.vue'),
          props: true,
          meta: {
            rule: 'organizations.edit',
            pageTitle: 'Organizações',
            breadcrumb: [
              { title: 'Educatena', url: '/' },
              { title: 'Organizações', url: '/organizations' },
              { title: 'Form', active: true }
            ]
          }
        },
        // Institution
        {
          path: '/institutions',
          name: 'institutions',
          component: () => import('./views/apps/institutions/Index.vue'),
          meta: {
            rule: 'institutions.grid',
            pageTitle: 'Instituições'
          }
        },
        {
          path: '/institutions/create',
          name: 'institutions_form_create',
          component: () => import('./views/apps/institutions/Form.vue'),
          props: true,
          meta: {
            rule: 'institutions.create',
            pageTitle: 'Instituições',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Instituições', url: '/institutions' },
              { title: 'Criar', active: true }
            ]
          }
        },
        {
          path: '/institutions/:id/edit',
          name: 'institutions_form_edit',
          component: () => import('./views/apps/institutions/Form.vue'),
          props: true,
          meta: {
            rule: 'institutions.edit',
            pageTitle: 'Instituições',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Instituições', url: '/institutions' },
              { title: 'Editar', active: true }
            ]
          }
        },
        // Courses
        {
          path: '/courses',
          name: 'courses',
          component: () => import('./views/apps/courses/Index.vue'),
          meta: {
            rule: 'courses.grid',
            pageTitle: 'Cursos'
          }
        },
        {
          path: '/courses/create',
          name: 'courses_form',
          component: () => import('./views/apps/courses/Form.vue'),
          props: true,
          meta: {
            rule: 'courses.create',
            pageTitle: 'Cursos',
            breadcrumb: [
              { title: 'Educatena', url: '/' },
              { title: 'Cursos', url: '/courses' },
              { title: 'Criar', active: true }
            ]
          }
        },
        {
          path: '/courses/:id/edit',
          name: 'courses_form_edit',
          component: () => import('./views/apps/courses/Form.vue'),
          props: true,
          meta: {
            rule: 'courses.edit',
            pageTitle: 'Cursos',
            breadcrumb: [
              { title: 'Educatena', url: '/' },
              { title: 'Cursos', url: '/courses' },
              { title: 'Editar', active: true }
            ]
          }
        },
        // Disciplines
        {
          path: '/disciplines',
          name: 'disciplines',
          component: () => import('./views/apps/disciplines/Index.vue'),
          meta: {
            rule: 'disciplines.grid',
            pageTitle: 'Disciplinas'
          }
        },
        {
          path: '/disciplines/create',
          name: 'disciplines_form_create',
          component: () => import('./views/apps/disciplines/Form.vue'),
          props: true,
          meta: {
            rule: 'disciplines.create',
            pageTitle: 'Disciplinas',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'disciplines', url: '/disciplines' },
              { title: 'Form', active: true }
            ]
          }
        },
        {
          path: '/disciplines/:id/edit',
          name: 'disciplines_form_edit',
          component: () => import('./views/apps/disciplines/Form.vue'),
          props: true,
          meta: {
            rule: 'disciplines.edit',
            pageTitle: 'Disciplinas',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'disciplines', url: '/disciplines' },
              { title: 'Form', active: true }
            ]
          }
        },
        // Email Templates
        {
          path: '/email_templates',
          name: 'disciplines',
          component: () => import('./views/apps/email_templates/Index.vue'),
          meta: {
            rule: 'email_templates.grid',
            pageTitle: 'Modelos de Email'
          }
        },
        {
          path: '/email_templates/create',
          name: 'email_templates_form_create',
          component: () => import('./views/apps/email_templates/Form.vue'),
          props: true,
          meta: {
            rule: 'email_templates.create',
            pageTitle: 'Modelos de Email',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Modelos de Email', url: '/email_templates' },
              { title: 'Criar', active: true }
            ]
          }
        },
        {
          path: '/email_templates/:id/edit',
          name: 'email_templates_form_edit',
          component: () => import('./views/apps/email_templates/Form.vue'),
          props: true,
          meta: {
            rule: 'email_templates.edit',
            pageTitle: 'Modelos de Email',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Modelos de Email', url: '/email_templates' },
              { title: 'Editar', active: true }
            ]
          }
        },
        // Campaign
        {
          path: '/campaigns',
          name: 'campaigns',
          component: () => import('./views/apps/campaigns/Index.vue'),
          meta: {
            rule: 'campaigns.grid',
            pageTitle: 'Campanhas'
          }
        },
        {
          path: '/campaigns/create',
          name: 'campaign_form_create',
          component: () => import('./views/apps/campaigns/Form.vue'),
          props: true,
          meta: {
            rule: 'campaigns.create',
            pageTitle: 'Campanha',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Campanha', url: '/campaigns' },
              { title: 'Criar', active: true }
            ]
          }
        },
        {
          path: '/campaigns/:id/edit',
          name: 'campaigns_form_edit',
          component: () => import('./views/apps/campaigns/Form.vue'),
          props: true,
          meta: {
            rule: 'campaigns.edit',
            pageTitle: 'Campanha',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Campanha', url: '/campaigns' },
              { title: 'Editar', active: true }
            ]
          }
        },
        //Students
        {
          path: '/students',
          name: 'students',
          component: () => import('./views/apps/students/Index.vue'),
          meta: {
            rule: 'students.grid',
            pageTitle: 'Estudantes'
          }
        },
        {
          path: '/students/create',
          name: 'students_form',
          component: () => import('./views/apps/students/Form.vue'),
          props: true,
          meta: {
            rule: 'students.create',
            pageTitle: 'Estudantes',
            breadcrumb: [
              { title: 'Educatena', url: '/' },
              { title: 'students', url: '/students' },
              { title: 'Form', active: true }
            ]
          }
        },
        {
          path: '/students/:id/edit',
          name: 'students_form_edit',
          component: () => import('./views/apps/students/Form.vue'),
          props: true,
          meta: {
            rule: 'students.edit',
            pageTitle: 'Estudantes',
            breadcrumb: [
              { title: 'Educatena', url: '/' },
              { title: 'students', url: '/students' },
              { title: 'Form', active: true },
            ]
          }
        },
        // Turmas
        {
          path: '/classes',
          name: 'classes',
          component: () => import('./views/apps/classes/Index.vue'),
          meta: {
            rule: 'classes.menu',
            pageTitle: 'Turmas'
          }
        },
        {
          path: '/classes_students',
          name: 'classes_students',
          component: () => import('./views/apps/classes_students/Index.vue'),
          meta: {
            rule: 'classes.students.menu|classes.menu',
            pageTitle: 'Minhas Turmas'
          }
        },
        {
          path: '/classes/create',
          name: 'classes_form_create',
          component: () => import('./views/apps/classes/Form.vue'),
          props: true,
          meta: {
            rule: 'classes.create',
            pageTitle: 'Turmas',
            breadcrumb: [
              { title: 'Educatena', url: '/' },
              { title: 'Turmas', url: '/classes' },
              { title: 'Form', active: true }
            ]
          }
        },
        {
          path: '/classes/:id/edit',
          name: 'classes_form_edit',
          component: () => import('./views/apps/classes/Form.vue'),
          props: true,
          meta: {
            rule: 'classes.edit',
            pageTitle: 'Turmas',
            breadcrumb: [
              { title: 'Educatena', url: '/' },
              { title: 'Turma', url: '/classes' },
              { title: 'Form', active: true }
            ]
          }
        },
        {
          path: '/class_invite',
          name: 'classes_invite',
          component: () => import('./views/apps/classes_students/Invite.vue'),
          props: true,
          meta: {
            rule: 'classes_students.invite',
            pageTitle: 'Confirmação convite'
          }
        },
        {
          path: '/class_invite/:invite_code',
          name: 'classes_invite',
          component: () => import('./views/apps/classes_students/Invite.vue'),
          props: true,
          meta: {
            rule: 'classes_students.invite',
            pageTitle: 'Confirmação convite'
          }
        },
        {
          path: '/classes_students/:class_id/overview',
          name: 'classes_overview',
          component: () => import('./views/apps/classes_students/view/ClassOverview.vue'),
          props: true,
          meta: {
            rule: 'classes_students.overview',
            pageTitle: 'Minha Turma'
          }
        },
        // Role
        {
          path: '/roles',
          name: 'roles',
          component: () => import('./views/apps/roles/Index.vue'),
          meta: {
            rule: 'roles.menu',
            pageTitle: 'Roles'
          }
        },
        {
          path: '/roles/create',
          name: 'roles_form',
          component: () => import('./views/apps/roles/Form.vue'),
          props: true,
          meta: {
            rule: 'roles.create',
            pageTitle: 'Roles',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Roles', url: '/roles' },
              { title: 'Form', active: true }
            ]
          }
        },
        {
          path: '/roles/:id/edit',
          name: 'roles_form_edit',
          component: () => import('./views/apps/roles/Form.vue'),
          props: true,
          meta: {
            rule: 'roles.edit',
            pageTitle: 'Roles',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Roles', url: '/roles' },
              { title: 'Form', active: true }
            ]
          }
        },

        // Base Question
        {
          path: '/base_question',
          name: 'base_question_manage',
          component: () => import('./views/apps/BaseQuestionManage.vue'),
          props: {
            mode: 'manage'
          },
          meta: {
            rule: 'base_question.menu',
            pageTitle: 'Banco de Questão',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Banco de Questão', active: true }
            ]
          }
        },
        // Reports - Presence
        {
          path: '/reports/questionnaire/presence',
          name: 'reports_questionnaire_presence',
          component: () => import('./views/apps/reports/QuestionnairePresenceReport.vue'),
          meta: {
            rule: 'reports.questionnaire.presence',
            pageTitle: 'Relatório de Presença',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Relatórios'},
              { title: 'Presença Questionário', active: true }
            ]
          }
        },
        // Reports - Answer Changes
        {
          path: '/reports/questionnaire/answer_change',
          name: 'reports_answer_change',
          component: () => import('./views/apps/reports/AnswerChangeReport.vue'),
          meta: {
            rule: 'reports.questionnaire.answer_change',
            pageTitle: 'Relatório de Alteração de Respostas',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Relatórios'},
              { title: 'Avaliação - Alteração de Resposta', active: true }
            ]
          }
        },
        // Reports - Score
        {
          path: '/reports/questionnaire/score',
          name: 'reports_questionnaire_score',
          component: () => import('./views/apps/reports/QuestionnaireScoreReport.vue'),
          meta: {
            rule: 'reports.questionnaire.score',
            pageTitle: 'Relatório de Notas',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Relatórios'},
              { title: 'Notas Questionário', active: true }
            ]
          }
        },
        // Reports - Pauses
        {
          path: '/reports/questionnaire/pause',
          name: 'reports_questionnaire_pause',
          component: () => import('./views/apps/reports/QuestionnairePauseReport.vue'),
          meta: {
            rule: 'reports.questionnaire.pause',
            pageTitle: 'Relatório de Pausas',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Relatórios'},
              { title: 'Avaliação - Pausas', active: true }
            ]
          }
        },
        // Reports - Performance
        {
          path: '/reports/questionnaire/performance',
          name: 'reports_questionnaire_performance',
          component: () => import('./views/apps/reports/QuestionnairePerformanceReport.vue'),
          meta: {
            rule: 'reports.questionnaire.performance',
            pageTitle: 'Relatório de Desempenho',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Relatórios'},
              { title: 'Desempenho Questionário', active: true }
            ]
          }
        },
        // Reports - Results
        {
          path: '/reports/questionnaire/results',
          name: 'reports_questionnaire_results',
          component: () => import('./views/apps/reports/QuestionnaireResultsReport.vue'),
          meta: {
            rule: 'reports.questionnaire.results',
            pageTitle: 'Relatório de Avaliações Respondidas',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Relatórios'},
              { title: 'Avaliação - Resposta', active: true }
            ]
          }
        },
        // Reports - Time
        {
          path: '/reports/questionnaire/time',
          name: 'reports_questionnaire_time',
          component: () => import('./views/apps/reports/QuestionnaireTimeReport.vue'),
          meta: {
            rule: 'reports.questionnaire.time',
            pageTitle: 'Relatório de Duração',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Relatórios'},
              { title: 'Avaliação - Duração', active: true }
            ]
          }
        },
        // Reports - Sent mail
        {
          path: '/reports/questionnaire/sent_email',
          name: 'reports_questionnaire_sent_email',
          component: () => import('./views/apps/reports/QuestionnaireSentEmailReport.vue'),
          meta: {
            rule: 'reports.questionnaire.sent_email',
            pageTitle: 'Avaliação - Relatório de E-mail Enviado',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Relatórios'},
              { title: 'Relatório - Avaliação - E-mail Enviado', active: true }
            ]
          }
        },
        // Questionnaire
        {
          path: '/questionnaires',
          name: 'questionnaires',
          component: () => import('./views/apps/questionnaires/Index.vue'),
          meta: {
            rule: 'questionnaires.menu',
            pageTitle: 'questionarios'
          }
        },
        {
          path: '/questionnaires/answers',
          name: 'questionnaires_answers',
          component: () => import('./views/apps/questionnaires/Answers.vue'),
          meta: {
            rule: 'questionnaires.menu.answers',
            pageTitle: 'meus-questionarios'
          }
        },
        {
          path: '/questionnaires/events',
          name: 'questionnaires_events',
          component: () => import('./views/apps/questionnaires/events/QuestionnaireEvents.vue'),
          meta: {
            rule: 'questionnaires.events.show',
            pageTitle: 'Sessão/Eventos'
          }
        },
        {
          path: '/user_client_exceptions',
          name: 'user_client_exceptions_index',
          component: () => import('./views/apps/user_client_exceptions/UserClientExceptions.vue'),
          meta: {
            rule: 'general_system_admin',
            pageTitle: 'Exceções de Usuário'
          }
        },
        {
          path: '/questionnaires/events/:id',
          name: 'questionnaires_events_details',
          component: () => import('./views/apps/questionnaires/events/QuestionnaireEventDetails.vue'),
          props: true,
          meta: {
            rule: 'questionnaires.events.show',
            pageTitle: 'Detalhamento de Eventos'
          }
        },
        {
          path: '/questionnaires/create',
          name: 'questionnaires_form',
          component: () => import('./views/apps/questionnaires/Form.vue'),
          props: true,
          meta: {
            rule: 'public',
            pageTitle: 'Questionário',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'questionario', url: '/questionnaires' },
              { title: 'Form', active: true }
            ],
            tourName: 'questionnaireSteps'
          }
        },
        {
          path: '/questionnaires/:id/edit',
          name: 'questionnaires_form_edit',
          component: () => import('./views/apps/questionnaires/Form.vue'),
          props: true,
          meta: {
            rule: 'questionnaires.edit',
            pageTitle: 'questionario',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'questionario', url: '/questionnaires' },
              { title: 'Form', active: true }
            ],
            tourName: 'questionnaireIntroSteps'
          }
        },
        {
          path: '/form/:short_url_id/finished',
          name: 'questionnaires_form_answers_finished',
          component: () =>
            import('./views/apps/questionnaires/answers/FinishedAnswer.vue'),
          props: true,
          meta: {
            rule: 'questionnaires.anwers'
          }
        },
        {
          path: '/form/:short_url_id/results',
          name: 'questionnaire_answer_results',
          component: () =>
            import(
              './views/apps/questionnaires/answers/QuestionnaireResults.vue'
            ),
          props: true,
          meta: {
            rule: 'questionnaires.menu.answers',
            pageTitle: 'Visualizar resultado',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Meus Questionários', url: '/questionnaires/answers' },
              { title: 'Visualizar resultado', active: true }
            ]
          }
        },
        {
          path: '/form/:short_url_id/results/:answer_id',
          name: 'questionnaire_answer_results',
          component: () =>
            import(
              './views/apps/questionnaires/answers/QuestionnaireResults.vue'
            ),
          props: true,
          meta: {
            rule: 'questionnaires.menu.answers',
            pageTitle: 'Visualizar resultado',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'meus-questionarios', url: '/questionnaires/answers' },
              { title: 'Visualizar resultado', active: true }
            ]
          }
        },
        {
          path: '/questionnaires/:id/resume',
          name: 'questionnaires_resume',
          component: () => import('./views/apps/questionnaires/Resume.vue'),
          props: true,
          meta: {
            rule: 'questionnaires.show',
            pageTitle: 'Resumo Questionário',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Questionário', url: '/questionnaires' },
              { title: 'Resumo', active: true }
            ],
          }
        },
        {
          path: '/questionnaires/:id/correction',
          name: 'questionnaires_correction',
          component: () => import('./views/apps/questionnaires/Correction.vue'),
          props: true,
          meta: {
            rule: 'questionnaires.show',
            pageTitle: 'Correção Questionário',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Questionário', url: '/questionnaires' },
              { title: 'Correction', active: true }
            ],
          }
        },
        // Chat
        {
          path: '/chat',
          name: 'chat',
          component: () => import('./chat/ChatApp.vue'),
          meta: {
            rule: 'chat.menu'
          }
        },
        {
          path: '/text_message',
          name: 'text_message',
          component: () => import('./views/apps/notifications-test/Form.vue'),
          props: true,
          meta: {
            rule: 'backoffice.communications.text_notification',
            pageTitle: 'Text Notification',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Text Notification', url: '/notifications-test' },
              { title: 'Form', active: true }
            ]
          }
        },
        {
          path: '/stream',
          name: 'stream',
          component: () => import('./views/apps/proctor/StreamView.vue'),
          meta: {
            pageTitle: 'Stream View'
          }
        },
        {
          path: '/meet',
          name: 'meet',
          component: () => import('./views/apps/proctor/MeetView.vue'),
          meta: {
            pageTitle: 'Meet View'
          }
        },
        // Proctoring
        {
          path: '/proctoring_monitor',
          name: 'proctoring_monitor',
          component: () => import('./views/apps/proctoring/ProctoringMonitor.vue'),
          props: true,
          meta: {
            rule: 'proctoring_monitor.all',
            pageTitle: 'Supervisão Geral',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Supervisão Geral', active: true},
            ]
          }
        },
        {
          path: '/proctoring_monitor/:questionnaireId',
          name: 'proctoring_monitor_questionnaire',
          component: () => import('./views/apps/proctoring/ProctoringMonitor.vue'),
          props: true,
          meta: {
            rule: 'proctoring.monitor',
            pageTitle: 'Supervisão',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Supervisão', url: '/proctoring_config'},
              { title: 'Supervisão - Todas', active: true},
            ]
          }
        },
        {
          path: '/proctoring_room/:roomId',
          name: 'proctoring_room',
          component: () => import('./views/apps/proctoring/ProctoringRoom.vue'),
          props: true,
          meta: {
            rule: 'proctoring.monitor',
            pageTitle: 'Supervisão - SAV',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Supervisão', url: '/proctoring_config'},
              { title: 'SAV', active: true}
            ]
          }
        },
        {
          path: '/proctoring_config',
          name: 'proctoring_config',
          component: () => import('./views/apps/proctoring/ProctoringConfig.vue'),
          props: true,
          meta: {
            rule: 'proctoring_config.show',
            pageTitle: 'Configurações Proctoring',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Supervisão', active: true}
            ]
          }
        },
        {
          path: '/proctoring_config/create',
          name: 'proctoring_config',
          component: () => import('./views/apps/proctoring/ProctoringConfigForm.vue'),
          props: true,
          meta: {
            rule: 'proctoring_config.create',
            pageTitle: 'Nova Supervisão',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Supervisão', url: '/proctoring_config'},
              { title: 'Nova', active: true},
            ]
          }
        },
        {
          path: '/proctoring_config/:id/edit',
          name: 'proctoring_config',
          component: () => import('./views/apps/proctoring/ProctoringConfigForm.vue'),
          props: true,
          meta: {
            rule: 'proctoring_config.edit',
            pageTitle: 'Editar Supervisão',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Supervisão', url: '/proctoring_config'},
              { title: 'Editar', active: true},
            ]
          }
        },
        {
          path: '/proctoring_audit',
          name: 'proctoring_audit',
          component: () => import('./views/apps/proctoring/ProctoringAudit.vue'),
          props: true,
          meta: {
            rule: 'proctoring.audit',
            pageTitle: 'Auditoria de avaliações',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Auditoria de avaliações', active: true},
            ]
          }
        },
        {
          path: '/proctoring_audit/:id',
          name: 'proctoring_audit_user_audit',
          component: () => import('./views/apps/proctoring/ProctoringClassroomUserAudit.vue'),
          props: true,
          meta: {
            rule: 'proctoring.audit',
            pageTitle: 'Auditoria de avaliações',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Auditoria de avaliações', url: '/proctoring_audit'},
              { title: 'Gravação', active: true}
            ]
          }
        },
        {
          path: '/proctoring_recording',
          name: 'proctoring_recording',
          component: () => import('./views/apps/proctoring/ProctoringRecording.vue'),
          props: true,
          meta: {
            rule: 'proctoring.recording.menu',
            pageTitle: 'Gravações das Avaliações',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Gravação de avaliação', url: '/proctoring_recording'},
              { title: 'Gravação', active: true}
            ]
          }
        },
        {
          path: '/support',
          name: 'support',
          component: () => import('./views/apps/support/Support.vue'),
          props: true,
          meta: {
            rule: 'support',
            pageTitle: 'Suporte',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Suporte', url: '/support'}
            ]
          }
        },
        {
          path: '/support/:roomId',
          name: 'support',
          component: () => import('./views/apps/support/Support.vue'),
          props: true,
          meta: {
            rule: 'support',
            pageTitle: 'Suporte',
            breadcrumb: [
              { title: 'Dashboard', url: '/' },
              { title: 'Suporte', url: '/support'}
            ]
          }
        },
        // News
        {
          path: '/news',
          name: 'news',
          component: () => import('./views/apps/news/Index.vue'),
          meta: {
            rule: 'general_system_admin',
            pageTitle: 'Avisos'
          }
        },
        {
          path: '/news/create',
          name: 'news_form',
          component: () => import('./views/apps/news/Form.vue'),
          props: true,
          meta: {
            rule: 'news.create',
            pageTitle: 'Avisos',
          }
        },
        {
          path: '/news/:id/edit',
          name: 'news_edit',
          component: () => import('./views/apps/news/Form.vue'),
          props: true,
          meta: {
            rule: 'news.edit',
            pageTitle: 'Avisos',
          }
        },
        // Missives
        {
          path: '/missives',
          name: 'missives',
          component: () => import('./views/apps/missives/MissiveIndex.vue'),
          meta: {
            rule: 'missives.show',
            pageTitle: 'Mala Direta'
          }
        },
        {
          path: '/missives/create',
          name: 'missives',
          props: true,
          component: () => import('./views/apps/missives/MissiveForm.vue'),
          meta: {
            rule: 'missives.create',
            pageTitle: 'Criar Mala Direta'
          }
        },
        {
          path: '/missives/:id/edit',
          name: 'missives',
          props: true,
          component: () => import('./views/apps/missives/MissiveForm.vue'),
          meta: {
            rule: 'missives.edit',
            pageTitle: 'Atualizar Mala Direta'
          }
        },
        // Certified user
        {
          path: '/certified_user',
          name: 'certified',
          component: () => import('./views/apps/certified/CertifiedUserIndex.vue'),
          meta: {
            rule: 'certified_user.grid',
            pageTitle: 'Certificados'
          }
        },
        // Certified config
        {
          path: '/certifieds',
          name: 'certifieds-grid',
          component: () => import('./views/apps/certified/CertifiedIndex.vue'),
          meta: {
            rule: 'certifieds.menu',
            pageTitle: 'Certificados - Configuração - listagem'
          }
        },
        {
          path: '/certifieds/create',
          name: 'certifieds-create',
          props: true,
          component: () => import('./views/apps/certified/CertifiedForm.vue'),
          meta: {
            rule: 'certifieds.create',
            pageTitle: 'Criar Certificados'
          }
        },
        {
          path: '/certifieds/:id/edit',
          name: 'certifieds-edit',
          props: true,
          component: () => import('./views/apps/certified/CertifiedForm.vue'),
          meta: {
            rule: 'certifieds.edit',
            pageTitle: 'Atualizar Certificados'
          }
        },
        {
          path: '/certified_emit/',
          name: 'certified-emit-index',
          props: true,
          component: () => import('./views/apps/certified/CertifiedEmitIndex.vue'),
          meta: {
            rule: 'certified_emit.grid',
            pageTitle: 'Certificados para Emissão'
          }
        },
        {
          path: '/certified_emit/:id/emit',
          name: 'certified-emit',
          props: true,
          component: () => import('./views/apps/certified/CertifiedEmit.vue'),
          meta: {
            rule: 'certified_emit.emit',
            pageTitle: 'Emitir Certificado'
          }
        },
        // Certified - Dashboard
        {
          path: '/certified_emit/dashboard',
          name: 'certified_emit_dashboard_index',
          props: true,
          component: () => import('./views/apps/certified/CertifiedEmitDashboardIndex.vue'),
          meta: {
            rule: 'certified_emit.dashboard.menu',
            pageTitle: 'Certificados - Dashboard'
          }
        },
        {
          path: '/certified_emit/:id/dashboard',
          name: 'certified_emit_dashboard',
          props: true,
          component: () => import('./views/apps/certified/CertifiedEmitDashboard.vue'),
          meta: {
            rule: 'certified_emit.dashboard.menu',
            pageTitle: 'Certificados - Dashboard'
          }
        },
        // Module
        {
          path: '/modules',
          name: 'modules',
          component: () => import('./views/apps/module/Index.vue'),
          meta: {
            rule: 'modules.show',
            pageTitle: 'Módulo'
          }
        },
        {
          path: '/modules/create',
          name: 'modules',
          props: true,
          component: () => import('./views/apps/module/Form.vue'),
          meta: {
            rule: 'modules.create',
            pageTitle: 'Criar Módulo'
          }
        },
        {
          path: '/modules/:id/edit',
          name: 'modules',
          props: true,
          component: () => import('./views/apps/module/Form.vue'),
          meta: {
            rule: 'modules.edit',
            pageTitle: 'Editar Módulo'
          }
        },
        //----
        // Functionality
        {
          path: '/functionalities',
          name: 'functionality_index',
          component: () => import('./views/apps/functionality/Index.vue'),
          meta: {
            rule: 'functionalities.show',
            pageTitle: 'Funcionalidade'
          }
        },
        {
          path: '/functionalities/create',
          name: 'functionality_create',
          props: true,
          component: () => import('./views/apps/functionality/Form.vue'),
          meta: {
            rule: 'functionalities.create',
            pageTitle: 'Criar Funcionalidade'
          }
        },
        {
          path: '/functionalities/:id/edit',
          name: 'functionality_edit',
          props: true,
          component: () => import('./views/apps/functionality/Form.vue'),
          meta: {
            rule: 'functionalities.edit',
            pageTitle: 'Editar Funcionalidade'
          }
        },
        //----
        // Permission
        {
          path: '/permissions',
          name: 'permission_index',
          component: () => import('./views/apps/permission/Index.vue'),
          meta: {
            rule: 'permissions.show',
            pageTitle: 'Permissão'
          }
        },
        {
          path: '/permissions/create',
          name: 'permission_create',
          props: true,
          component: () => import('./views/apps/permission/Form.vue'),
          meta: {
            rule: 'permissions.create',
            pageTitle: 'Criar Permissão'
          }
        },
        {
          path: '/permissions/:id/edit',
          name: 'permission_edit',
          props: true,
          component: () => import('./views/apps/permission/Form.vue'),
          meta: {
            rule: 'permissions.edit',
            pageTitle: 'Editar Permissão'
          }
        },
        //----
        // Data Access
        {
          path: '/data_access',
          name: 'data_access_index',
          component: () => import('./views/apps/data_access/Index.vue'),
          meta: {
            rule: 'data_access.show',
            pageTitle: 'Data Access'
          }
        },
        {
          path: '/data_access/create',
          name: 'data_access_create',
          props: true,
          component: () => import('./views/apps/data_access/Form.vue'),
          meta: {
            rule: 'data_access.create',
            pageTitle: 'Criar Data Access'
          }
        },
        {
          path: '/data_access/:id/edit',
          name: 'data_access_edit',
          props: true,
          component: () => import('./views/apps/data_access/Form.vue'),
          meta: {
            rule: 'data_access.edit',
            pageTitle: 'Editar Data Access'
          }
        },
        // Practical Test
        {
          path: '/practical_tests',
          name: 'practical_test_grid',
          component: () => import('./views/apps/practical_test/PracticalTestIndex.vue'),
          meta: {
            rule: 'practical_tests.grid',
            pageTitle: 'Prova Prática'
          }
        },
        {
          path: '/practical_tests/create',
          name: 'practical_test_create',
          props: true,
          component: () => import('./views/apps/practical_test/PracticalTestForm.vue'),
          meta: {
            rule: 'practical_tests.create',
            pageTitle: 'Criar Prova Prática'
          }
        },
        {
          path: '/practical_tests/:id/edit',
          name: 'practical_test_edit',
          props: true,
          component: () => import('./views/apps/practical_test/PracticalTestForm.vue'),
          meta: {
            rule: 'practical_tests.edit',
            pageTitle: 'Atualizar Prova Prática'
          }
        },
        // Practical Test - Teacher
        {
          path: '/practical_tests/tests',
          name: 'practical_test_teacher',
          props: true,
          component: () => import('./views/apps/practical_test/PracticalTestTeacherIndex.vue'),
          meta: {
            rule: 'practical_tests.grid',
            pageTitle: 'Prova Prática - Avaliação'
          }
        },
        {
          path: '/practical_tests/:id/teachers/:teacherId/students',
          name: 'practical_test_teacher_student',
          props: true,
          component: () => import('./views/apps/practical_test/PracticalTestStudentIndex.vue'),
          meta: {
            rule: 'practical_tests.grid',
            pageTitle: 'Prova Prática - Alunos'
          }
        },
        {
          path: '/practical_tests/:id/teachers/:teacherId/students/:identifier',
          name: 'practical_test_teacher_student',
          props: true,
          component: () => import('./views/apps/practical_test/PracticalTestStudentIndex.vue'),
          meta: {
            rule: 'practical_tests.grid',
            pageTitle: 'Prova Prática - Alunos'
          }
        },
        // Practical Test - Dashboard
        {
          path: '/practical_tests/dashboard',
          name: 'practical_test_dashboard_index',
          props: true,
          component: () => import('./views/apps/practical_test/PracticalTestDashboardIndex.vue'),
          meta: {
            rule: 'practical_tests.dashboard.menu',
            pageTitle: 'Prova Prática - Dashboard'
          }
        },
        // Questionnaire Correction - discursive and redaction
        {
          path: '/questionnaire_correctors',
          name: 'questionnaire_corrector_grid',
          component: () => import('./views/apps/questionnaire_corrector/QuestionnaireCorrectorIndex.vue'),
          meta: {
            rule: 'questionnaire_correctors.grid',
            pageTitle: 'Correção de Prova - Configuração - Listagem'
          }
        },
        {
          path: '/questionnaire_correctors/create',
          name: 'questionnaire_corrector_create',
          props: true,
          component: () => import('./views/apps/questionnaire_corrector/QuestionnaireCorrectorForm.vue'),
          meta: {
            rule: 'questionnaire_correctors.create',
            pageTitle: 'Correção de Prova - configuração'
          }
        },
        {
          path: '/questionnaire_correctors/:id/edit',
          name: 'questionnaire_corrector_edit',
          props: true,
          component: () => import('./views/apps/questionnaire_corrector/QuestionnaireCorrectorForm.vue'),
          meta: {
            rule: 'questionnaire_correctors.edit',
            pageTitle: 'Correção de Prova - edição da configuração'
          }
        },
        {
          path: '/questionnaire_correctors/corrections',
          name: 'questionnaire_correction_grid',
          component: () => import('./views/apps/questionnaire_corrector/QuestionnaireQuestionCorrectionIndex.vue'),
          meta: {
            rule: 'questionnaire_correctors.teacher.menu',
            pageTitle: 'Correção de Prova - Listagem'
          }
        },
        {
          path: '/questionnaire_correctors/:questionnaireCorrectorId/answer/:answerId/correction',
          name: 'questionnaire_question_correction',
          props: true,
          component: () => import('./views/apps/questionnaire_corrector/QuestionnaireQuestionCorrection.vue'),
          meta: {
            rule: 'questionnaire_correctors.teacher.evaluate',
            pageTitle: 'Correção de Prova'
          }
        },
        {
          path: '/questionnaire_correctors/dashboard',
          name: 'questionnaire_corrector_dashboard_index',
          props: true,
          component: () => import('./views/apps/questionnaire_corrector/QuestionnaireCorrectorDashboardIndex.vue'),
          meta: {
            rule: 'questionnaire_correctors.dashboard.menu',
            pageTitle: 'Correção de Prova - Dashboard'
          }
        },
        {
          path: '/questionnaire_correctors/:id/dashboard',
          name: 'questionnaire_corrector_dashboard',
          props: true,
          component: () => import('./views/apps/questionnaire_corrector/QuestionnaireCorrectorDashboard.vue'),
          meta: {
            rule: 'questionnaire_correctors.dashboard.menu',
            pageTitle: 'Correção de Prova - Dashboard'
          }
        },
        {
          path: '/emails_sent',
          name: 'email_sent_index',
          props: true,
          component: () => import('./views/apps/email_sent/Index.vue'),
          meta: {
            rule: 'emails_sent.menu',
            pageTitle: 'E-mail enviado'
          }
        },
        {
          path: '/backoffice/questionnaire',
          name: 'backoffice_questionnaire',
          props: true,
          component: () => import('./views/apps/backoffice/QuestionnaireForm.vue'),
          meta: {
            rule: 'backoffice.questionnaire',
            pageTitle: 'Back Office - Questionário'
          }
        },
        {
          path: '/backoffice/user',
          name: 'backoffice_user',
          props: true,
          component: () => import('./views/apps/backoffice/UserForm.vue'),
          meta: {
            rule: 'backoffice.user',
            pageTitle: 'Back Office - Usuário'
          }
        },// Plans
        {
          path: '/plans',
          name: 'plan_grid',
          component: () => import('./views/apps/plan/PlanIndex.vue'),
          meta: {
            rule: 'plans.grid',
            pageTitle: 'Planos'
          }
        },
        {
          path: '/plans/create',
          name: 'plan_create',
          props: true,
          component: () => import('./views/apps/plan/PlanForm.vue'),
          meta: {
            rule: 'plans.create',
            pageTitle: 'Criar Planos'
          }
        },
        {
          path: '/plans/:id/edit',
          name: 'plan_edit',
          props: true,
          component: () => import('./views/apps/plan/PlanForm.vue'),
          meta: {
            rule: 'plans.edit',
            pageTitle: 'Editar Planos'
          }
        },// Billings
        {
          path: '/billing',
          name: 'plan_billing',
          component: () => import('./views/apps/plan/billing/PlanBillingIndex.vue'),
          meta: {
            rule: 'billing.grid',
            pageTitle: 'Faturamento'
          }
        },
        {
          path: '/billing/create',
          name: 'plan_billing_create',
          props: true,
          component: () => import('./views/apps/plan/billing/PlanBillingForm.vue'),
          meta: {
            rule: 'billing.create',
            pageTitle: 'Criar Faturamento'
          }
        },
        {
          path: '/billing/:id/edit',
          name: 'plan_billing_edit',
          props: true,
          component: () => import('./views/apps/plan/billing/PlanBillingForm.vue'),
          meta: {
            rule: 'billing.edit',
            pageTitle: 'Editar Faturamento'
          }
        },
        {
          path: '/my_subscriptions',
          name: 'my_subscriptions',
          component: () => import('./views/apps/plan/billing/PlanMySubscriptionIndex.vue'),
          meta: {
            rule: 'plans.subscription.grid',
            pageTitle: 'Assinatura'
          }
        },
        //----
        // Product
        {
          path: '/products',
          name: 'product_index',
          component: () => import('./views/apps/marketing_place/product/Index.vue'),
          meta: {
            rule: 'products.grid',
            pageTitle: 'Produto'
          }
        },
        {
          path: '/products/create',
          name: 'product_create',
          props: true,
          component: () => import('./views/apps/marketing_place/product/Form.vue'),
          meta: {
            rule: 'products.create',
            pageTitle: 'Criar Produto'
          }
        },
        {
          path: '/products/:id/edit',
          name: 'product_edit',
          props: true,
          component: () => import('./views/apps/marketing_place/product/Form.vue'),
          meta: {
            rule: 'products.edit',
            pageTitle: 'Editar Produto'
          }
        },
        // Marketing Place Config
        {
          path: '/marketing_place/',
          name: 'marketing_place_config',
          props: true,
          component: () => import('./views/apps/marketing_place/config/Form.vue'),
          meta: {
            rule: 'marketing_place.config.menu',
            pageTitle: 'Marketing Place - Configuração'
          }
        },
        // Marketing Place Corporate Sales
        {
          path: '/marketing_place/store',
          name: 'marketing_place_corp_index',
          props: true,
          component: () => import('./views/apps/marketing_place/store/FormCorporate.vue'),
          meta: {
            rule: 'marketing_place.corporate.menu',
            pageTitle: 'Marketing Place - Vendas Corporativas'
          }
        },
        // Orders
        {
          path: '/orders',
          name: 'order_index',
          props: true,
          component: () => import('./views/apps/marketing_place/order/Index.vue'),
          meta: {
            rule: 'orders.grid',
            pageTitle: 'Pedidos'
          }
        },
        {
          path: '/orders/:id/payment',
          name: 'order_pay',
          props: true,
          component: () => import('./views/apps/marketing_place/order/PayPurchaseOrder.vue'),
          meta: {
            rule: 'orders.pay',
            pageTitle: 'Pedidos de Compra - Pagamento'
          }
        },
        {
          path: '/orders/:id/detail',
          name: 'order_detail',
          props: true,
          component: () => import('./views/apps/marketing_place/order/PurchaseOrderDetail.vue'),
          meta: {
            rule: 'orders.detail',
            pageTitle: 'Pedidos de Compra - Detalhamento'
          }
        },
         //Appeal --------------------------------------
         {
          path: '/appeals',
          name: 'appeal_index',
          component: () => import('./views/apps/appeal/Index.vue'),
          meta: {
            rule: 'functionalities.show',
            pageTitle: 'Recurso'
          }
        },
        {
          path: '/appeals/create',
          name: 'appeal_create',
          props: true,
          component: () => import('./views/apps/appeal/Form.vue'),
          meta: {
            rule: 'appeals.create',
            pageTitle: 'Solicitar Recurso'
          }
        },
        {
          path: '/appeals/:id/edit',
          name: 'appeal_edit',
          props: true,
          component: () => import('./views/apps/appeal/Form.vue'),
          meta: {
            rule: 'appeals.edit',
            pageTitle: 'Editar Recurso'
          }
        },
        {
          path: '/appeals/:id/detail',
          name: 'appeal_detail',
          props: true,
          component: () => import('./views/apps/appeal/FormDetail.vue'),
          meta: {
            rule: 'appeals.detail',
            pageTitle: 'Detalhar Recurso'
          }
        },
        {
          path: '/appeal_handlings',
          name: 'appeal_handlings_index',
          component: () => import('./views/apps/appeal/handling/Index.vue'),
          meta: {
            rule: 'appeal_handlings.show',
            pageTitle: 'Recurso - Tratamento'
          }
        },
        {
          path: '/appeal_handlings/:id/handling',
          name: 'appeal_handlings_handling',
          props: true,
          component: () => import('./views/apps/appeal/handling/Form.vue'),
          meta: {
            rule: 'appeal_handlings.edit',
            pageTitle: 'Recurso - Tratamento'
          }
        },
        {
          path: '/appeal_handlings/:id',
          name: 'appeal_handlings_handling_detail',
          props: true,
          component: () => import('./views/apps/appeal/handling/FormDetail.vue'),
          meta: {
            rule: 'appeal_handlings.detail',
            pageTitle: 'Recurso - Tratamento - Detalhamento'
          }
        },
        //--------------------------------------
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/slogin/:s_email',
          name: 's-login',
          props: true,
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/sslogin',
          name: 'ss-login',
          component: () => import('@/views/pages/login/SLogin.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/ilogin',
          name: 'i-login',
          component: () => import('@/views/pages/login/iLogin.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('@/views/pages/Register.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/social_register/:token/:provider/:email/:name',
          name: 'social_register',
          props: true,
          component: () => import('@/views/pages/Register.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/verified',
          name: 'verified',
          component: () => import('@/views/pages/login/Verified.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/reset-password/:token',
          name: 'reset-password',
          props: true,
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/first-access',
          name: 'first-access',
          props: true,
          component: () => import('@/views/pages/FirstAccess.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/not-authorized',
          name: 'not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/error-404',
          name: 'error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/invalid-signature',
          name: 'invalid-signature',
          component: () => import('@/views/pages/InvalidSignature.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/checkout/:hash',
          name: 'plan_checkout',
          component:() => import ('@/views/apps/checkout/PlanCheckoutPage.vue'),
          props: true,
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/checkout/:mode/:hash',
          name: 'plan_checkout',
          component:() => import ('@/views/apps/checkout/PlanCheckoutPage.vue'),
          props: true,
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/version',
          name: 'version',
          component: () => import('@/views/pages/Version.vue'),
          meta: {
            rule: 'public'
          }
        },
        // Questionnaire
        {
          path: '/form/:short_url_id',
          name: 'questionnaires_form_answers',
          component: () =>
            import('./views/apps/questionnaires/answers/FormAnswer.vue'),
          props: true,
          meta: {
            rule: 'questionnaires.menu.answers|questionnaires.can_answer'
          }
        },
        {
          path: '/form/:short_url_id/lead/:lead_id',
          name: 'questionnaires_form_answers_lead',
          component: () =>
            import('./views/apps/questionnaires/answers/FormAnswer.vue'),
          props: true,
          meta: {
            rule: 'questionnaires.menu.answers|questionnaires.can_answer'
          }
        },
        {
          path: '/form/:short_url_id/preview',
          name: 'questionnaires_form_answers_preview',
          component: () =>
            import('./views/apps/questionnaires/answers/FormAnswerPreview.vue'),
          props: true,
          meta: {
            rule: 'questionnaires.preview'
          }
        },
        {
          path: '/gform/:short_url_id',
          name: 'questionnaires_template_form_answers',
          component: () =>
            import('./views/apps/questionnaires/answers/GameFormAnswer.vue'),
          props: true,
          meta: {
            rule: 'questionnaires.menu.answers|questionnaires.can_answer'
          }
        },
        {
          path: '/form_practical/:practical_test_student_id/:section_id',
          name: 'form_practical_test_form',
          component: () =>
            import('./views/apps/questionnaires/answers/FormAnswer.vue'),
          props: true,
          meta: {
            rule: 'questionnaires.menu.answers|questionnaires.can_answer'
          }
        },
        {
          path: '/questionnaire/:questionnaire_short_url/result/:answer_id/print',
          name: 'questionnaire_answer_print',
          component: () =>
            import(
              './views/apps/questionnaires/answers/AnswerResult.vue'
            ),
          props: true,
        },
        {
          path: '/form/:short_url_id/results/:answer_id/full',
          name: 'questionnaire_answer_results_full',
          component: () =>
            import(
              './views/apps/questionnaires/answers/QuestionnaireResults.vue'
            ),
          props: true,
          meta: {
            rule: 'questionnaires.menu.answers',
            pageTitle: 'Visualizar resultado'
          }
        },
        // Reports - Results - Printable form
        {
          path: '/reports/questionnaire/results/:mode',
          name: 'reports_questionnaire_results_print',
          component: () => import('./views/apps/reports/QuestionnaireResultsReport.vue'),
          props: true,
          // meta: {
          //   rule: 'reports.questionnaire.results',
          //   pageTitle: 'Relatório de Resultados',
          //   breadcrumb: [
          //     { title: 'Dashboard', url: '/' },
          //     { title: 'Relatórios'},
          //     { title: 'Resultados Questionário', active: true }
          //   ]
          // }
        },
        {
          path: '/campaigns/:code',
          name: 'campaigns_form_lead',
          component: () => import('./views/apps/campaigns/FormLead.vue'),
          props: true,
        },
        {
          path: '/privacy-policy',
          name: 'privacy_policy',
          component: () => import('./views/pages/PrivacyPolicy.vue'),
        },
        {
          path: '/terms-service',
          name: 'terms_service',
          component: () => import('./views/pages/TermsService.vue'),
        },
        {
          path: '/certified_user/:code/validate',
          name: 'certified_user_validate',
          props: true,
          component: () => import('@/views/pages/CertifiedUserValidate.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/document_persons/:type/:token/upload',
          name: 'document_person_upload',
          props: true,
          component: () => import('@/views/apps/document/DocumentUpload.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/document_capture_finished',
          name: 'document_capture_finished',
          props: true,
          component: () => import('@/views/apps/document/DocumentCaptureFinished.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/questionnaires/:id/print',
          name: 'questionnaires_print',
          component: () => import('./views/apps/questionnaires/QuestionnairePrint.vue'),
          props: true,
          meta: {
            rule: 'questionnaires.print',
            pageTitle: 'Impressão',
          }
        },
        {
          path: '/maintenance',
          name: 'maintenance',
          props: true,
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: '/practical_tests/:id/dashboard',
          name: 'practical_test_dashboard',
          props: true,
          component: () => import('./views/apps/practical_test/PracticalTestDashboard.vue'),
          meta: {
            rule: 'practical_tests.dashboard.menu',
            pageTitle: 'Prova Prática - Dashboard'
          }
        },
        // Marketing Place Store of Sales
        {
          path: '/store/:encrypParam',
          name: 'store_index',
          props: true,
          component: () => import('./views/apps/marketing_place/store/FormShopping.vue'),
          meta: {
            pageTitle: 'Marketing Place'
          }
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(() => {

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

const DEFAULT_TITLE: string = `${process.env.VUE_APP_NAME}`;
router.beforeEach((to, from, next) => {
  store.commit('common/DISABLED_CONTEXT_MENU', false)

  const frozenRouter = store.getters['frozenRouter']

  if (frozenRouter) {
    console.log('router is currently frozen')
    next(false)
    return
  }

  const acl = Vue.prototype.$acl
  const profile = store.getters.activeProfile
  const userInfo: any = localStorage.getItem('userInfo')

  // Set the institution permissions to acl when the page is refreshed.
  if (from.name === null) {
    let institutionsStore: any = localStorage.getItem('institutionStore')
    let institutions: any = null
    if (institutionsStore) {
      institutionsStore = JSON.parse(institutionsStore)
      institutions = institutionsStore.institutions
    }

    const activeInstitution = store.getters['institution/active']
    if (activeInstitution && activeInstitution.id && institutions) {
      const institution = institutions.find(institution => {
        return institution.id === activeInstitution.id
      })
      if (institution) {
        acl.setPermissions(institution.permissions)
      }
    } else if (userInfo) {
      const userPermissions: any = JSON.parse(userInfo).permissions
      acl.setPermissions(userPermissions)
    }
  }

  // Check if user has permission to access the route
  if (userInfo !== null) {
    const isNotPublicisPublic = to.meta.rule && to.meta.rule !== 'public'
    if (!acl.hasAnyPermission(to.meta.rule) && isNotPublicisPublic) {
      next('/not-authorized')
    }

    // Set title
    document.title = to.meta.pageTitle ? DEFAULT_TITLE + ' - ' + to.meta.pageTitle : DEFAULT_TITLE
  }

  // Set last url path
  let appInfoStr: any = localStorage.getItem('appInfo')
  let appInfo: any = appInfoStr ? JSON.parse(appInfoStr) : {}
  if (
    to.path !== '/login' &&
    to.name !== 'error-404' &&
    to.name !== 'reset-password' &&
    to.name !== 'not-authorized' &&
    to.name !== 'invalid-signature' &&
    to.name !== 'forgot-password' &&
    to.name !== 'social_register' &&
    to.name !== 'verified' &&
    to.name !== 'register') {
    if (!appInfo) {
      appInfo = {
        lastUrlPath: to.path
      }
    } else {
      appInfo.lastUrlPath = to.path
    }
    localStorage.setItem('appInfo', JSON.stringify(appInfo))
  }

  if ( to.name === 'questionnaires_form_answers_preview' && isUserLoggedIn() === false) {
    /**
     * Rule: Questionnaire preview cant be accessed by unlogged users.
     * Why: Preview and non preview uses the same permission: questionnaires.menu.answers
     */
    next('/login')
    return
  }

  if (
    to.path !== '/login' &&
    to.name !== 'error-404' &&
    to.name !== 'forgot-password' &&
    to.name !== 'reset-password' &&
    to.name !== 'not-authorized' &&
    to.name !== 'invalid-signature' &&
    to.name !== 'verified' &&
    to.name !== 'register' &&
    to.name !== 'questionnaires_form_answers' &&
    to.name !== 'restricted_user' &&
    to.name !== 'first-access' &&
    from.name !== 'first-access' &&
    to.name !== 'campaigns_form_lead' &&
    from.name !== 'login' &&
    from.name !== 's-login' &&
    from.name !== 'ss-login' &&
    from.name !== 'i-login' &&
    from.name !== 'document_person_upload' &&
    to.name !== 'plan_checkout' &&
    to.name !== 'terms_service' &&
    to.name !== 'privacy_policy' &&
    to.name !== 'certified_user_validate' &&
    to.name !== 'i-login' &&
    to.name !== 'document_person_upload' &&
    to.name !== 'document_capture_finished'
  ) {
    const service = LoginService.build()
    const notificationService = NotificationService.build(router.app)
    next()
    service.post('v1/logged', {}).then(
      data => {
        router.app.$store.dispatch('resetUnreadNotifications', notificationService.constructManyNotifications(data.notifications))
        router.app.$store.dispatch('setPingInterval', data.ping_interval)
        if ('slow_kbps_rate' in data) router.app.$store.dispatch('setSlowKbpsRate', data.slow_kbps_rate)
        router.app.$store.dispatch('updateUserInfo', {email_verified_at: data.email_verified_at})
        Vue.prototype.$acl.setPermissions(data.permissions)
        if (process.env.VUE_APP_ANALYTICS_ENABLED === 'true') {
          page(to.path)
        }
        // this.$store.getters.appVersion
        console.log('Current app version: ', router.app.$store.getters.appVersion)
        console.log('New app version: ', data.front_app_version)
        if (('front_app_version' in data)
              && data.front_app_version > router.app.$store.getters.appVersion
                && data.front_app_version !== -1
        ) {
          console.log('atualizando...')
          location.reload(true)
        } else {
          // next()
        }
      },
      error => {
        const app: any = router.app
        if (from.name !== null) {
          Vue.prototype.notifyError(app.$vs, 'Usuário não logado.')
          next('/login')
          return
        }
        if (error.status !== 401) {
          router.app.$store.dispatch('auth/logout', {vs: app.$vs})
        }
      }
    )
  } else {
    next()
  }

  if (to.path === '/login' && isUserLoggedIn()) {
    if (
      isUserLoggedIn() &&
      !Vue.prototype.isEmpty(store.getters.userToken)// &&
      // !(
      //   from.path ===  '/restricted_user' &&
      //   to.path === '/login'
      // )
    ) {
      let app: any = router.app
      app.notifyWarning(app.$vs, 'Você já está autenticado.', 3000)
    }
    next('/')
    return
  }

  const routePath = to.fullPath
  if (routePath.includes('#')) {
    const split = routePath.split('#')
    if (split.length > 1) {
      const redirect = split[split.length - 1]
      console.log('Duplicate hash navigation detected, redirecting.', split, redirect)
      next(redirect)
      return
    }
  }
})

export default router
